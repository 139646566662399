@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  @import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

  #home {
    background-color: #383F51;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%234bc6b9' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23dddbf1' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%2326afb7' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23c5d6f4' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%231397af' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23a5d2f2' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23227ea2' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%2380cfe9' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%2333668e' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%235dcbd6' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%233c4f76' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%234bc6b9' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
  }

  nav {
    background-color: #383F51;
  }
}
